import isMobile from 'ismobilejs';
import Swal from 'sweetalert2'

import listings from './listings'

/********************************
    GA Tracking
/********************************/
window.dataLayer = window.dataLayer || [];

function gtag() { dataLayer.push(arguments); }
gtag('js', new Date());
gtag('config', 'UA-xxx-xx');


window.onload = () => {
    setTimeout(() => {
        // alert('hoasdf')
        history.pushState(null, '', '/');
    }, 500)
}
const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});


const showOverlay = (obj) => {
    // console.log(obj)
    Swal.fire({
        imageUrl: obj.imageUrl,
        // title: '<strong>View Listing</strong>',
        // icon: 'info',
        html: obj.listingHTML,
        // showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: '<i></i> YES',
        // confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText: '<i>No Thanks</i>'
    }).then((result) => {
        const isConfirmed = result.isConfirmed
        if (isConfirmed) {
            window.open(obj.listingURL, "Sanan Cohrs - listing")
            gtag('event', 'click', {
                'event_category': 'listing',
                'value': obj.listingURL
            });
            // alert('yes')            
        } else{
            gtag('event', 'click', {
                'event_category': 'listing',
                'value': 'no'
            });
            console.log('no') // alert('no')
        }
    })
}

let listing = params.l;
if (listing && listings[listing]) {
    const imageUrl = require('../listing.jpg')
    const listingURL = listings[listing].url
    const listingHTML = `
    <h4>${listings[listing].street}</h4>
     ${listings[listing].city}
     <p>View Listing</p>`;
    const listigObj = {
        imageUrl,
        listingHTML,
        listingURL
    }
    showOverlay(listigObj)
}

const links = document.querySelectorAll('a')
links.forEach((link) => {
    link.onclick = (evt) => {
        let href = link.href

        let category = 'contact';
        if (href.includes('.vcf'))
            category = 'download contact'
        else if (href.includes('http'))
            category = 'external site'

        if (isDescendant(evt.target, 'text-button')) {
            href = smsMessage(href)
            evt.preventDefault()
        }

        gtag('event', 'click', {
            'event_category': category,
            'value': href
        });
    }
})

/********************************
    send SMS instead of calling
/********************************/
function smsMessage(link) {
    const message = "Hi Sanan, "
    const number = link.replace('tel', 'sms')
    link = `${number}?body=${message}`
    if (isMobile(window.navigator).apple.device) {
        link = `${number}?body=${message}`

    } else if (isMobile(window.navigator).android.device) {
        link = `${number}?body=${message}`
    }
    window.open(link);
    return link
}


/********************************
    find if element is a decendant by ID
/********************************/
const isDescendant = (el, parentId) => {
    let isChild = false

    if (el.id === parentId) { //is this the element itself?
        isChild = true
    }

    while (el = el.parentNode) {
        if (el.id == parentId) {
            isChild = true
        }
    }

    return isChild
}